<template>
  <file-picker @close="modal.close()" @complete="add">
    <template #title>
      Add items to {{contextType}} <em>"{{context.name}}"</em>
    </template>
  </file-picker>
</template>

<script setup>

  const props = defineProps({
    context: Object,
    contextType: String,
    shareToken: String,
    modal: Object
  });

  const currentUserId = useSessionStore().currentUserId;
  const inCollaboration = computed(() => props.context.can_contribute && props.context.user?.id !== currentUserId && props.shareToken);
  const filesStore = useFilesStore();

  function add({items: files}) {
    if (inCollaboration.value) {
      addToCollaborativeAlbum({files});
    } else {
      addToContainer({files});
    }
  }

  async function addToContainer({files}) {
    const ids = files.map(f => f.id);

    try {
      await useFilesStore().addFilesToContext({contextId: props.context.id, contextType: props.contextType, ids});

      useSuccessToast().add({
        description: `The selected files were added to "${props.context.name}".`
      });

      props.modal.close();

      filesStore.getFiles({reload: true});
    } catch (e) {
      useErrorToast().add();
    }
  }

  async function addToCollaborativeAlbum({files}) {
    const copyables = files.filter(f => f.is.copyableFromCurrentUser);
    const uncopyables = files.filter(f => !f.is.copyableFromCurrentUser);

    try {
      if (copyables.length) {
        await useFileApi().copyToCollaborativeAlbum({
          ids: copyables.map(f => f.id),
          albumId: props.context.id,
          userId: props.context.user.id,
          shareToken: props.shareToken
        });

        useSuccessToast().add();
      }

      if (uncopyables.length) {
        useErrorToast().add({
          description: `We could not copy ${uncopyables.length} of the selected files because they are still **in progress**.`
        });
      }

      props.modal.close();

      filesStore.getFiles({reload: true});

    } catch (e) {
      if (e.response && e.response.status === 413) {
        useErrorToast().add({
          description: `We could not copy the selected files because ${props.context.userName}'s FOREVER Storage® account is out of space.`
        });
      } else {
        useErrorToast().add();
      }
    }
  }


</script>
