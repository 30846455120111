import {AlbumEditForm, AlbumShareModal, AlbumMoveModal, AlbumDeleteModal, AlbumCopyModal, AlbumDetailsModal, FileUpdateContextThumbnailModal} from '#components';

export function useAlbumActions() {
  function addEditAlbum({parent, album = null, buttonSaveText = null, showSuccessToast} = {}) {
    //if no album (not editing), and parent cannot have children, display the too many nested album message and reject
    if (parent && !parent.canHaveChildren && !album) {
      useErrorToast().add({
        id: 'add-album-too-deep',
        description: `You've reached the maximum album depth and cannot create an album here.`
      })

      //otherwise open the modal
    } else {
      return new Promise(async resolve => {

        //editing a list item, get detail
        if (album && !album.share_token) {
          album = await useAlbumsStore().getAlbumsOne({id: album.id});
        }

        const modal = useFModal({
          id: 'add-edit-album',
          size: 'lg',
          clickToClose: false,
          component: AlbumEditForm,
          props: {
            album,
            parent,
            buttonSaveText,
            showSuccessToast
          },
          eventHandlers: {
            success({newAlbum}) {
              resolve(newAlbum);
              modal.close();
            }
          }
        });

        modal.open();
      })
    }
  }

  async function share({album}) {

    return new Promise(async resolve => {
      if (!album.share_token) {
        album = await useAlbumsStore().getAlbumsOne({id: album.id});
      }

      const modal = useFModal({
        id: 'album-share-modal',
        size: 'lg',
        component: AlbumShareModal,
        props: {
          album
        },
        eventHandlers: {
          success() {
            resolve();
            modal.close();
          }
        }
      });

      modal.open();
    });
  }

  function move({albums, currentParentId = null} = {}) {
    return new Promise(resolve => {
      const modal = useFModal({
        id: 'album-move-modal',
        component: AlbumMoveModal,
        props: {
          albums,
          currentParentId
        },
        eventHandlers: {
          success({destAlbum}) {
            modal.close();

            //update the current album detail
            if (albums.length === 1 && useAlbumsStore().album?.id === albums[0].id) {
              useAlbumsStore().getAlbum({id: albums[0].id});
            }

            resolve({destAlbum});
          }
        }
      });

      modal.open();
    });
  }

  async function copy({album}) {

    if (album && !album.user) {
      album = await useAlbumsStore().getAlbumsOne({
        id: album.id,
        userId: album.user_id,
        shareToken: album.shareToken
      });
    }

    useFModal({
      id: 'album-copy-modal',
      component: AlbumCopyModal,
      props: {
        album
      }
    }).open();
  }

  function editThumbnail({album}) {
    const modal = useFModal({
      id: 'edit-album-thumbnail',
      component: FileUpdateContextThumbnailModal,
      size: 'fullscreen',
      showClose: false,
      props: {
        contextType: FILE_CONTEXTS.album,
        context: album
      },
      eventHandlers: {
        async complete(file) {
          try {
            await useFileActions().setFileAsAlbumCover({album, file});
            modal.close();
          } catch (e) {
            useErrorToast().add();
          }
        }
      }
    });

    modal.open();
  }

  function deleteAlbums({albums}) {
    return new Promise(resolve => {
      const modal = useFModal({
        id: 'delete-album',
        component: AlbumDeleteModal,
        props: {
          albums
        },
        eventHandlers: {
          success() {
            modal.close();
            resolve();
          }
        }
      });
      modal.open();
    });
  }

  function removeVisitedAlbum({album}) {
    return new Promise(resolve => {
      useConfirmModal({
        heading: `Remove Visited Album?`,
        message: `Remove the album <strong>${album.name}</strong> from your visited albums? This will remove access unless you have the original share link.`,
        confirmMethod: async () => {
          try {
            await useAlbumsStore().removeVisitedAlbums({ids: [album.sharedItemId]});
            useSuccessToast().add({
              title: 'Album Removed',
              description: `The album <strong>${album.name}</strong> has been removed.`
            });
          } catch (e) {
            useErrorToast().add();
          }
        },
        onConfirm: () => resolve()
      }).open();
    });
  }

  function viewDetails({album}) {
    useFModal({
      id: 'album-details',
      component: AlbumDetailsModal,
      size: 'lg',
      props: {
        album
      }
    }).open();
  }

  function getContextActions({album, userId, shareToken}) {
    return {
      upload: {
        label: 'Upload Files',
        icon: COMMON_ICONS.upload,
        click: () => useUploadActions().openUploaderModal({album, shareToken, contextType: FILE_CONTEXTS.album, contextId: album.id})
      },
      addFiles: {
        label: 'From Gallery',
        icon: COMMON_ICONS.addFiles,
        click: () => useFileActions().addFilesToContext({context: album, contextType: FILE_CONTEXTS.album, shareToken})
      },
      viewDetails: {
        label: 'View Details',
        icon: 'i-ri-information-2-line',
        click: () => viewDetails({album})
      },
      share: {
        label: 'Share',
        icon: COMMON_ICONS.share,
        click: () => share({album})
      },
      print: {
        label: 'Print',
        icon: COMMON_ICONS.print,
        click: () => {
          if (!album.files_count) {
            useInfoToast().add({
              description: `You must add files to this album before you can Print it.`
            });
          } else {
            useProjectActions().openContainerPrintModal({
              context: album,
              contextType: FILE_CONTEXTS.album
            });
          }
        }
      },
      edit: {
        label: 'Edit Details',
        icon: COMMON_ICONS.edit,
        click: () => addEditAlbum({album})
      },
      editThumbnail: {
        label: 'Edit Cover Photo',
        icon: COMMON_ICONS.makeCover,
        click: () => {
          if (!album.files_count) {
            useInfoToast().add({
              description: `You must add files to this album before you can set a cover photo.`
            });
          } else {
            editThumbnail({album});
          }

        }
      },
      move: {
        label: 'Move Album',
        icon: 'i-ri-folder-transfer-line',
        click: async () => {
          const {destAlbum: id} = await move({
            albums: [album],
            currentParentId: album.parent_album?.id
          });

          const {album_id_or_slug: albumId} = useRoute().params;
          if (albumId) {
            await useAlbumsStore().refreshCurrentAlbum();
            useEventBus(ALBUM_EVENTS.navigationRefresh).emit();
          } else {
            useAlbumsStore().getAlbumsOne({id});
            useAlbumsStore().removeAlbumsOne({id: album.id});
          }
        }
      },
      addNested: {
        label: 'Add Nested Album',
        icon: 'i-ri-folder-add-line',
        click: async () => {
          try {
            await addEditAlbum({parent: album});
          } catch (e) {
            useErrorToast().add('');
          }
        }
      },
      viewSlideshow: {
        label: 'View Slideshow',
        icon: COMMON_ICONS.slideshow,
        click: () => useSlideshow({
          container: album,
          fileContext: FILE_CONTEXTS.album,
          userId,
          shareToken
        }).start()
      },
      download: {
        label: 'Download Album',
        icon: COMMON_ICONS.download,
        click: () => {
          if (!album.files_count) {
            useInfoToast().add({
              description: `You must add files to this album before you can download it.`
            });
          } else {
            useFileActions().downloadContext({
              context: album,
              contextType: FILE_CONTEXTS.album,
              userId
            });
          }
        }
      },
      copy: {
        label: 'Copy Album',
        icon: COMMON_ICONS.copy,
        click: () => copy({album})
      },
      delete: {
        label: 'Delete',
        icon: COMMON_ICONS.delete,
        click: async () => {
          await deleteAlbums({albums: [album]});
          const route = album.parent_album
            ? useBuildRoute().toAlbum({
              album: album.parent_album,
              userId
            })
            : useBuildRoute().toAlbums();
          navigateTo(route);
        }
      },
      removeVisited: {
        label: 'Remove Album',
        icon: 'i-ri-close-circle-line',
        click: () => removeVisitedAlbum({album})
      }
    };
  }

  return {
    addEditAlbum,
    share,
    move,
    copy,
    editThumbnail,
    deleteAlbums,
    removeVisitedAlbum,
    viewDetails,
    getContextActions
  };
}
