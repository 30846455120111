<template>
  <vue-date-picker
    v-model="internalRange"
    range
    multi-calendars
    auto-apply
    format="M/d/yyyy"
    timezone="UTC"
    month-name-format="long"
    inline
    :enable-time-picker="false"
    :year-range="yearRange"
    :clearable="false"
    :is-24="false"
  />
</template>

<script setup>
  import VueDatePicker from '@vuepic/vue-datepicker';

  const props = defineProps({
    yearRange: {
      type: Array,
      default: [1500, useDayjs().utc().year()]
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const internalRange = computed({
    get() {
      return dateRange.value?.val;
    },
    set(value) {
      dateRange.value.val = [
        dayJs(value[0]).utc().startOf('day'),
        dayJs(value[1]).utc().endOf('day')
      ];
    }
  });

  onMounted(() => {
    if (internalRange.value) {
      internalRange.value = [
        internalRange.value[0].year(Math.max(dayJs(internalRange.value[0]).year(), props.yearRange[0])),
        internalRange.value[1].year(Math.min(dayJs(internalRange.value[1]).year(), props.yearRange[1]))
      ];
    }
  });

</script>
