import {defineStore} from 'pinia';

const BUFFER_PAGES_COUNT = 1;
const dayjs = useDayjs();

export const makeFilesStore = (id = 'filesStore') => defineStore(id, {
  state: () => ({
    files: null,
    filesCount: null,
    bufferedPages: null,
    hasMorePages: false,
    hasMore: false,
    cursor: null,
    isBuffering: false,
    isPageQueued: false,
    collectionDescriptor: null,
    isLoadingFiles: false,

    fileDetails: null,
    fileDetailRequestTracker: new Set(),
    file: null
  }),

  actions: {
    /**
     * when reload is true all of the parameters will be preserved but files will be cleared and loading will start again from page 1
     */
    async getFiles({
      nextPage,
      reload,
      userId,
      order,
      albums = [],
      tags = [],
      people = [],
      fileTypes = [],
      organization = [],
      dateRange = {},
      addedSince,
      search,
      contextType,
      context,
      shareToken,
      projectsOnly,
      projectsCategory
    } = {}) {

      const resetTracking = () => {
        this.cursor = null;
        this.filesCount = null;
        this.bufferedPages = [];
        this.hasMore = false;
      };

      if (!nextPage && !reload) {
        resetTracking();

        this.collectionDescriptor = {
          userId,
          order,
          albums,
          tags,
          people,
          fileTypes,
          organization,
          addedSince,
          search,
          contextType,
          context,
          shareToken,
          dateRange,
          projectsOnly,
          projectsCategory,
          matchesLoadedFiles: false
        };
        this.getFilesCount();
      }
      if (reload) {
        resetTracking();

        const reloadEvent = useEventBus('files-reload-start');
        reloadEvent.emit();
        this.getFilesCount();
      }

      const fetchPage = async () => {
        const data = projectsOnly
          ? await useProjectsApi().getProjects({
            cursor: this.cursor,
            search: this.collectionDescriptor.search,
            filter: this.collectionDescriptor.projectsCategory
          })
          : await useFileApi().getFiles({
            cursor: this.cursor,
            userId: this.collectionDescriptor.userId,
            order: this.collectionDescriptor.order,
            albumIds: this.collectionDescriptor.albums?.map(a => a.id),
            tagIds: this.collectionDescriptor.tags?.map(t => t.id),
            personIds: this.collectionDescriptor.people?.map(p => p.id),
            fileTypes: this.collectionDescriptor.fileTypes,
            organization: this.collectionDescriptor.organization,
            startDate: this.collectionDescriptor.dateRange?.val && this.collectionDescriptor.dateRange.val[0],
            endDate: this.collectionDescriptor.dateRange?.val && this.collectionDescriptor.dateRange.val[1],
            addedSince: this.collectionDescriptor.addedSince,
            search: this.collectionDescriptor.search,
            contextType: this.collectionDescriptor.contextType,
            contextId: this.collectionDescriptor.context?.id,
            shareToken: this.collectionDescriptor.shareToken
          });

        this.hasMorePages = data.files.length === data.per_page;
        this.cursor = data.next_cursor;

        return data;
      };
      const bufferPages = async () => {
        if (this.isBuffering) {
          return;
        }

        this.isBuffering = true;

        while (this.hasMorePages && this.bufferedPages?.length < BUFFER_PAGES_COUNT) {
          const pageData = await fetchPage();
          this.bufferedPages?.push(pageData);

          if (this.isPageQueued) {
            processPage();
          }
        }

        this.isBuffering = false;
      };
      const processPage = ({page, resetFiles} = {}) => {
        this.isPageQueued = false;

        const thePage = page || this.bufferedPages?.shift();

        if (this.hasMore && !thePage) {
          //note: there are more pages, but the buffer is empty - queue up a page to process when the buffer gets something
          this.isPageQueued = true;
          return;
        }

        //note: modify this to apply file-mapper if/when deemed necessary
        const groupedFiles = this.addFileGroupData(thePage.files);

        if (resetFiles) {
          this.files = [];
          this.collectionDescriptor.matchesLoadedFiles = true;
        }

        this.files = [...this.files, ...groupedFiles];
        this.hasMore = Boolean(this.bufferedPages.length || this.hasMorePages);
      }

      try {
        this.files = this.files || [];
        this.bufferedPages = this.bufferedPages || [];
        this.isLoadingFiles = true;

        const page = nextPage
          ? null
          : await fetchPage();

        processPage({page, resetFiles: !nextPage || reload});
      } catch (e) {
        throw e;
      } finally {
        this.isLoadingFiles = false;
      }

      if (process.client) {
        bufferPages();
      }

      return this.files;
    },

    async getFilesCount() {
      if (this.collectionDescriptor) {
        /*this.filesCount = await useFileApi().getFilesCount({
          userId: this.collectionDescriptor.userId,
          order: this.collectionDescriptor.order,
          albumIds: this.collectionDescriptor.albums?.map(a => a.id),
          tagIds: this.collectionDescriptor.tags?.map(t => t.id),
          personIds: this.collectionDescriptor.people?.map(p => p.id),
          fileTypes: this.collectionDescriptor.fileTypes,
          organization: this.collectionDescriptor.organization,
          startDate: this.collectionDescriptor.dateRange?.val && this.collectionDescriptor.dateRange.val[0],
          endDate: this.collectionDescriptor.dateRange?.val && this.collectionDescriptor.dateRange.val[1],
          addedSince: this.collectionDescriptor.addedSince,
          search: this.collectionDescriptor.search,
          contextType: this.collectionDescriptor.contextType,
          contextId: this.collectionDescriptor.context?.id,
          shareToken: this.collectionDescriptor.shareToken
        });*/
      }
    },

    clearFiles() {
      this.files = null;
      this.filesCount = null;
      this.bufferedPages = null;
      this.hasMore = false;
      this.cursor = null;
      this.collectionDescriptor = null;
      this.fileDetails = null;
      this.fileDetailRequestTracker = new Set();
      this.file = null;
    },

    async clearFilters() {

      this.collectionDescriptor.albums = [];
      this.collectionDescriptor.tags = [];
      this.collectionDescriptor.people = [];
      this.collectionDescriptor.fileTypes = [];
      this.collectionDescriptor.organization = [];
      this.collectionDescriptor.dateRange = null;
      await this.getFiles({reload: true});
    },

    prependFiles({files}) {
      this.files.unshift(...files);
    },

    async getFileDetails({files, userId, shareToken, contextType, contextId, refresh}) {
      const requests = [];
      const filesNeedDetails = refresh
        ? files
        : files.filter(f => (!f.is?.detail || f.is.processing) && !this.fileDetailRequestTracker.has(f.id));

      for (let i = 0; i < filesNeedDetails.length; i += 20) {
        const fileIdsForRequest = filesNeedDetails.slice(i, i + 20).map(f => f.id);

        fileIdsForRequest.forEach(fId => this.fileDetailRequestTracker.add(fId));

        requests.push(useFileApi().getFileDetails(
          fileIdsForRequest,
          {
            contextId,
            contextType,
            userId,
            shareToken
          })
        );
      }

      const detailedRecordsArr = await Promise.all(requests);
      const detailedRecords = this.addFileGroupData(detailedRecordsArr.reduce((accum, arr) => accum.concat(arr), []));
      this.fileDetails = this.fileDetails || [];
      this.fileDetails.push(...detailedRecords);

      //merge with files
      for (let i = 0; i < this.fileDetails.length; i++) {
        this.fileDetailRequestTracker.delete(this.fileDetails[i].id);

        const fd = this.fileDetails[i];

        //set loaded file
        if (this.file?.id === fd.id) {
          this.file = fd;
        }

        //merge with list files
        if (this.files && (!contextId || this.isSameAsLoadedFilesContext({contextType, contextId}))) {
          const fileIndex = this.files.findIndex(f => f.id === fd.id);

          if (fileIndex > -1) {
            this.files[fileIndex] = fd;// Object.assign(this.files[fileIndex], fd);
            this.fileDetails.splice(i, 1);
            i--;
          }
        }
      }

      return detailedRecords;
    },

    isSameAsLoadedFilesContext({contextType, contextId}) {
      const isMatchingFileContext = (this.collectionDescriptor?.contextType === contextType && this.collectionDescriptor?.context?.id === contextId)
        || (contextType === FILE_CONTEXTS.project && this.collectionDescriptor?.projectsOnly)
        || contextType === FILE_CONTEXTS.gallery;


      return isMatchingFileContext && this.collectionDescriptor?.matchesLoadedFiles;
    },

    insertFile({file, afterFile}) {
      let index = this.files.findIndex(f => f.id === afterFile.id);
      const toInsert = this.addFileGroupData([file])[0];

      if (index > -1) {
        this.files.splice(index + 1, 0, toInsert);
      }
    },

    setFile({file}) {
      this.file = file;
    },

    async updateFile({id, updates: {name, description, taken_at, rotation, file_url, favorite}}) {
      const persistRotation = ((rotation % 360) + 360) % 360;

      const resFile = await useFileApi().updateFile({id, updates: {name, description, taken_at, rotation: persistRotation, file_url, favorite}});

      const updated = this.addFileGroupData([resFile])[0]; //todo: remove uploaded_at

      //if we rotated the file, we need to update the context to reflect the new display
      if (rotation && this.collectionDescriptor.context?.id && this.collectionDescriptor.contextType) {
        this.updateContext({
          contextId: this.collectionDescriptor.context?.id,
          contextType: this.collectionDescriptor.contextType
        })
      }

      //if rotation was updated we need to assign the UI value used, which may differ from the stored one. This is so the rotation transition plays correctly in the lightbox
      updated.images.rotation = rotation;

      const listFileIndex = this.files.findIndex(file => file.id === updated.id);
      this.files[listFileIndex] = Object.assign(this.files[listFileIndex], updated);

      if (this.file?.id === this.files[listFileIndex].id) {
        this.setFile({file: this.files[listFileIndex]});
      }

      return updated;
    },

    async addFilesToFavorites({files}) {
      const ids = files.map(f => f.id);

      await useFileApi().updateFiles({ids, updates: {favorite: true}});

      this.files.forEach(file => {
        if (ids.includes(file.id)) {
          file.favorite = true;
        }
      })
    },


    async refreshFileDetails({updatedAlbum, updatedUser, updatedTag, updatedPerson}) {
      if (!this.files) {return;}

      let entityId;
      let entityKey;

      if (updatedAlbum) {
        entityId = updatedAlbum.id;
        entityKey = 'albums';
      } else if (updatedUser) {
        entityId = updatedUser.id;
        entityKey = 'user';
      } else if (updatedTag) {
        entityId = updatedTag.id;
        entityKey = 'tags';
      } else if (updatedPerson) {
        entityId = updatedPerson.id;
        entityKey = 'people';
      }

      // Get the ids of the files that have been flagged as detail and contain a certain entity id
      const files = this.files
        .filter(file => {
          if (file.is?.detail) {
            if (entityKey === 'user') {
              return file[entityKey]?.id === entityId;
            } else {
              return file[entityKey]?.some(entity => entity.id === entityId);
            }
          }
        });

      // Fetch and splice the new details
      await this.getFileDetails({files, refresh: true});
    },

    invalidateFileDetails() {
      this.files.forEach(file => {
        if (file.is?.detail) {
          file.is.detail = false;
        }
      });
    },

    async disableShareToken({id}) {
      await useFileApi().disableShareToken({id});

      this.file.share_token = null;
    },

    async resetShareToken({id}) {
      this.file.share_token = await useFileApi().resetShareToken({id});
    },

    addFileGroupData(files) {
      return this.collectionDescriptor?.order?.isGrouped
        ? files.map(file => {

          let dateForGroupName = dayjs(file[this.collectionDescriptor.order.field]);

          if (this.collectionDescriptor.order.adjustToUTC) {
            dateForGroupName = dateForGroupName.utc();
          }

          file.group = {
            name: dateForGroupName.format('ll'),
            fieldVal: file[this.collectionDescriptor.order.field]
          };

          return file;
        })
        : files;
    },


    /**
     * Removes files to the library and updates the library if the current context is the library.
     * @param files - array of file objects
     * @returns {Promise<void>}
     */
    async removeFilesFromLibrary({ids}){
      await useFileApi().deleteFiles({ids});
      this.files = this.files.filter(f => !ids.includes(f.id));
      useSessionStore().getDeleteBinSummary();
      this.getFilesCount();
    },


    /**
     * Adds files to a context and updates the context if the context matches the current context.
     * @param ids - array of file ids
     * @param contextType - album, tag, person
     * @param contextId - id of the context
     * @returns {Promise<void>}
     */
    async addFilesToContext({ids, contextType, contextId}) {
      await useFileApi().addFilesToContext({ids, contextType, contextId});
      this.updateNegativelyFilteredFiles({ids, contextType, contextId});
      await this.updateContext({contextId, contextType});
    },


    /**
     * Removes files from a context and updates the context if the context matches the current context.
     * @param ids - array of file ids
     * @param contextType - album, tag, person
     * @param contextId - id of the context
     * @returns {Promise<void>}
     */
    async removeFilesFromContext({ids, contextType, contextId}) {
      await useFileApi().removeFilesFromContext({ids, contextType, contextId});

      if (contextType === this.collectionDescriptor.contextType && contextId === this.collectionDescriptor.context?.id) {
        this.files = this.files.filter(f => !ids.includes(f.id));
      }

      await this.updateContext({contextId, contextType});
    },

    /**
     * Checks if the loaded files are filtered by a negative attribute (such as "not in album" or "not in tag")
     * if so, removes the passed in ids if the context checks out
     * @param ids
     * @param contextType
     * @param contextId
     */
    updateNegativelyFilteredFiles({ids, contextType}) {
      const updateForAlbum = this.collectionDescriptor.organization.includes(FILE_FILTERS.notInAlbum) && contextType === FILE_CONTEXTS.album;
      const updateForTag = this.collectionDescriptor.organization.includes(FILE_FILTERS.notTagged) && contextType === FILE_CONTEXTS.tag;

      if (updateForAlbum || updateForTag) {
        this.files = this.files.filter(f => !ids.includes(f.id));
      }
    },


    /**
     * Updates the context of the current page if the context matches the current context.
     * I realize this results in multiple calls for the same entity, but it's the simplest
     * way to ensure the context is updated.
     * @param contextId
     * @param contextType
     * @returns {Promise<void>}
     */
    //todo: can we put the get<Context>One calls in else blocks to avoid duplicate requests?
    async updateContext({contextId, contextType, shareToken}) {
      if (contextType === 'album') {
        if (contextId === useAlbumsStore().album?.id) {
          useAlbumsStore().getAlbum({id: contextId, shareToken});
        }
        useAlbumsStore().getAlbumsOne({id: contextId, shareToken});
      } else if (contextType === 'tag') {
        if (contextId === useTagsStore().tag?.id) {
          useTagsStore().getTag({id: contextId, shareToken});
        }
        useTagsStore().getTagsOne({id: contextId, shareToken});
      } else if (contextType === 'person') {
        if (contextId === usePeopleStore().person?.id) {
          usePeopleStore().getPerson({id: contextId, shareToken});
        }
        usePeopleStore().getPeopleOne({id: contextId, shareToken});
      }

      if (contextType === this.collectionDescriptor.contextType && contextId === this.collectionDescriptor.context?.id) {
        this.getFilesCount();
      }
    },

    async reorderFiles({album, files, position}) {
      let targetPosition = position;

      //adjust the requested position for the API
      files.forEach(file => {
        const fileIndex = this.files.findIndex(f => file.id === f.id);

        this.files.splice(fileIndex, 1);

        if (fileIndex < position) {
          targetPosition--;
        }
      });

      this.files.splice(targetPosition, 0, ...files);

      return useFileApi().reorderFiles(album, files, targetPosition);
    },



    async purgeFiles({ids}) {
      await useFileApi().purgeDeletedFiles({ids});
      this.files = this.files.filter(f => !ids.includes(f.id));
    },

    async restoreFiles({ids}) {
      await useFileApi().restoreFiles({ids});
      this.files = this.files.filter(f => !ids.includes(f.id));
      useSessionStore().getDeleteBinSummary();
    }
  },

  getters: {
    areFiltersApplied: state => Boolean(
      state.collectionDescriptor?.albums?.length ||
      state.collectionDescriptor?.tags?.length ||
      state.collectionDescriptor?.people?.length ||
      state.collectionDescriptor?.fileTypes?.length ||
      state.collectionDescriptor?.organization?.length ||
      state.collectionDescriptor?.dateRange?.val
    ),

    filterCount: state => {
      let count = 0;

      if (state.collectionDescriptor?.albums?.length) {
        count += state.collectionDescriptor.albums.length;
      }

      if (state.collectionDescriptor?.tags?.length) {
        count += state.collectionDescriptor.tags.length;
      }

      if (state.collectionDescriptor?.people?.length) {
        count += state.collectionDescriptor.people.length;
      }

      if (state.collectionDescriptor?.fileTypes?.length) {
        count += state.collectionDescriptor.fileTypes.length;
      }

      if (state.collectionDescriptor?.organization?.length) {
        count += state.collectionDescriptor.organization.length;
      }

      if (state.collectionDescriptor?.dateRange?.val) {
        count++;
      }

      return count;
    },

    filesCountFallback: state => {
      if (state.files?.length < 50) {
        return state.files.length;
      } else {
        return '50+';
      }
    }
  }
});

export const useFilesStore = makeFilesStore();
