<template>
  <div class="f-album_navigator text-left border-2 border-charcoal-200 bg-white">
    <!--breadcrumbs-->
    <ul class="crumbs list-none flex items-center p-2 border-b border-gray-300 leading-none text-sm">

      <!--if breadcrumb length is less than 2, show the root crumb-->
      <li v-if="breadcrumbs.length < 2">
        <u-icon name="i-ri-book-line" class="text-base" />
        <a v-if="!currentSet.isRoot" href="#" @click.prevent="getAlbums()">Albums</a>
        <span v-else>Albums</span>
      </li>

      <!--iterate over crumbs, only show last two in array at most-->
      <li class="ellipsis-overflow" v-for="(breadcrumb, index) in displayedBreadcrumbs" :key="breadcrumb.id">
        <u-icon name="i-ri-book-line" v-if="breadcrumbs.length > 1 && index === 0" class="text-base" />
        <a v-if="breadcrumb.id !== currentSet.id" href="#" @click.prevent="getAlbums({parentAlbum: breadcrumb, isAscending: true})">{{breadcrumb.name}}</a>
        <span v-else>{{breadcrumb.name}}</span>
      </li>

    </ul>

    <ul class="list-none p-0 overflow-x-hidden overflow-y-auto h-110 scrollbar-light" ref="albumsList">
      <li v-if="isCreateVisible || isRootVisible" class="p-2 border-b border-gray-300 flex">
        <u-button v-if="isCreateVisible" :icon="COMMON_ICONS.add" size="xs" label="New Album" @click="addAlbum(currentSet)" />
        <u-button v-if="isRootVisible" icon="i-ri-reply-fill" size="xs" label="Top Level" @click="select({})" />
      </li>

      <li
          v-for="(album, index) in currentAlbums"
          :key="index"
          @click.stop="select(album)"
          class="relative p-2 odd:bg-gray-100 transition-all break-normal"
          :class="isAlbumDisabled(album) ? 'cursor-not-allowed before:absolute-cover before:bg-white before:!right-12 before:bg-opacity-70 z-10' : 'cursor-pointer hover:bg-charcoal-100'">

        <div class="flex items-center gap-3">
          <album-avatar :album="album" size="md" />

          <div class="flex-1 flex flex-col justify-center">
            <p class="text-sm font-semibold">{{album.name}}</p>
            <p class="text-xs text-gray-500">
              <template v-if="album.files_count && album.albums_count">
                {{album.files_count}} Files, {{album.albums_count}} Nested Albums
              </template>
              <template v-else-if="album.files_count">
                {{album.files_count}} Files
              </template>
              <template v-else-if="album.albums_count">
                {{album.albums_count}} Nested Albums
              </template>
            </p>
          </div>

          <u-button
            v-if="showNestedButtonForAlbum(album)"
            icon="i-heroicons-chevron-right"
            color="charcoal"
            @click.stop="getAlbums({parentAlbum: album})"
          />
        </div>
      </li>
    </ul>

  </div>
</template>

<script setup>
  import throttle from 'lodash.throttle';

  const emit = defineEmits(['change', 'select']);
  const props = defineProps({
    disableIds: {
      type: Array,
      default: () => []
    },
    parentId: String,
    showRoot: {
      type: Boolean,
      default: false
    },
    showCreate: {
      type: Boolean,
      default: false
    },
    allowChildrenOfDisabled: Boolean
  });

  const albumsList = ref();
  const isGettingAlbums = ref(false);
  const isLoadingMore = ref(false);
  const isSelectionMade = ref(false);
  const isRootVisible = computed(() => props.showRoot && currentSet.isRoot && props.parentId);
  const isCreateVisible = computed(() => props.showCreate && currentSet.isRoot);

  const currentSet = reactive({
    hasMore: false,
    privacy: null,
    id: null,
    isRoot: false,
    nextCursor: null
  });

  const currentAlbums = ref([]);

  const breadcrumbs = ref([]);
  const displayedBreadcrumbs = computed(() => breadcrumbs.value.slice(breadcrumbs.value.length - 2));

  async function getAlbums({parentAlbum, isAscending} = {}) {
    if (isGettingAlbums.value) {
      return;
    }

    isGettingAlbums.value = true;

    const parentAlbumId = parentAlbum?.id;
    const data = await useAlbumApi().getAlbums({parentAlbumId});

    currentSet.nextCursor = data.next_cursor;
    currentSet.isRoot = !parentAlbumId;
    currentSet.id = parentAlbumId;
    currentSet.privacy = parentAlbum?.privacy;
    currentSet.hasMore = data.albums.length === data.per_page && data.next_cursor;
    currentAlbums.value = data.albums;

    if (!parentAlbumId) {
      breadcrumbs.value = [];

      //breadcrumb click
    } else if (isAscending) {
      const index = breadcrumbs.value.findIndex(bc => bc.id === parentAlbumId);
      breadcrumbs.value.length = index + 1;

      //not root, push parent
    } else {
      breadcrumbs.value.push(parentAlbum);
    }

    //reset scrolltop
    const navigatorListEl = document.getElementsByClassName('f-album_navigator-list')[0];

    if (navigatorListEl) {
      navigatorListEl.scrollTop = 0;
    }

    isGettingAlbums.value = false;

    emit('change');
  }

  async function nextPage() {
    if (isLoadingMore.value) {
      return;
    }

    isLoadingMore.value = true;

    if (currentSet.hasMore) {
      const data = await useAlbumApi().getAlbums({parentAlbumId: currentSet.id, cursor: currentSet.nextCursor});

      currentSet.nextCursor = data.next_cursor;
      currentSet.hasMore = data.albums.length === data.per_page && data.next_cursor;
      currentAlbums.value.push(...data.albums);
    }

    isLoadingMore.value = false;
  }

  function select(selection) {
    if (!isAlbumDisabled(selection) && !isSelectionMade.value) {
      isSelectionMade.value = true;
      emit('select', selection);
      setTimeout(() => (isSelectionMade.value = false), 1000);
    }
  }

  function showNestedButtonForAlbum(album) {
    const hasChildren = album.albums_count;
    const nothingDisabled = !props.disableIds.length && hasChildren;
    const albumRestrict = hasChildren && !props.disableIds.includes(album.id);

    if (props.allowChildrenOfDisabled && hasChildren) {
      return true;
    }

    return nothingDisabled || albumRestrict;
  }

  async function addAlbum(parent) {
    const validParent = parent.id ? parent : null;
    const newAlbum = await useAlbumActions().addEditAlbum({
      buttonSaveText: 'Add to New Album',
      parent: validParent,
      showSuccessToast: false
    });
    select(newAlbum);
  }

  function isAlbumDisabled(album) {
    return props.disableIds.includes(album.id) || album.id === props.parentId;
  }

  getAlbums();

  let scrollPosition
  const checkLoadMore = throttle(() => {
    if (!currentSet.hasMore) {
      return;
    }

    const viewPortBottom = scrollPosition.value + albumsList.value.offsetHeight;
    const scrollHeight = albumsList.value.scrollHeight;

    if (viewPortBottom >= scrollHeight - 1000) {
      nextPage();
    }

  }, 100);

  onMounted(() => {
    //scroll bottom
    const {y} = useScroll(albumsList.value);
    scrollPosition = y;

    watch(scrollPosition, checkLoadMore);
  });

</script>

<style scoped lang="scss">
.f-album_navigator {
  /* breadcrumbs */
  .crumbs {
    li {
      @apply flex items-center before:content-['>'] before:mx-2;
    }

    li:first-child {
      @apply before:hidden gap-2;
    }
  }
}
</style>
