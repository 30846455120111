import plugin from 'tailwindcss/plugin';
import defaultTheme from 'tailwindcss/defaultTheme';
import colors from './conf/tailwind-colors.js';

export default {
  darkMode: 'class', // 'class' or 'media'
  content: [
    './components/**/*.{vue,js}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.js',
    './services/upload/**/*.js', //note: an exception
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/data/table.mjs',
    //'!./node_modules/@nuxt/ui/dist/runtime/ui.config/elements/accordion.mjs',
    //'!./node_modules/@nuxt/ui/dist/runtime/ui.config/elements/chip.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/elements/kbd.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/elements/progress.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/layout/card.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/layout/container.mjs',
    //'!./node_modules/@nuxt/ui/dist/runtime/ui.config/layout/divider.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/navigation/breadcrumb.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/navigation/commandPalette.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/navigation/pagination.mjs',
    //'!./node_modules/@nuxt/ui/dist/runtime/ui.config/navigation/tabs.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/navigation/verticalNavigation.mjs',
    //'!./node_modules/@nuxt/ui/dist/runtime/ui.config/overlays/contextMenu.mjs',
    '!./node_modules/@nuxt/ui/dist/runtime/ui.config/overlays/modal.mjs'
  ],
  theme: {
    colors,
    screens: {
      xs: '480px',
      sm: '768px',
      md: '992px',
      lg: '1200px',
      xl: '1400px',
      '2xl': '1800px',
      '3xl': '2400px'
    },
    fontWeight: {
      light: '300',
      normal: '400',
      semibold: '600',
      bold: '700'
    },
    fontFamily: {
      sans: ['ProximaNova', ...defaultTheme.fontFamily.sans],
      serif: ['Caladea', ...defaultTheme.fontFamily.serif],
      mono: [...defaultTheme.fontFamily.mono]
    },
    extend: {
      fontSize: {
        '2xs': ['0.625rem', { lineHeight: '1' }],
        '8xl': ['5.25rem', { lineHeight: '1' }],
        '9xl': ['6rem', { lineHeight: '1' }]
      },
      spacing: {
        '104': '26rem',
        '110': '28rem',
        '116': '30rem',
        '124': '32rem'
      }
    }
  },

  plugins: [
    function({ addVariant }) {
      addVariant('firefox', ':-moz-any(&)')
    },
    plugin(({ addBase, theme }) => {
      addBase({
        'html, body': {
          color: theme('colors.charcoal.500')
        },
        'body': {
          '-webkit-font-smoothing': 'antialiased'
        },

        'a': {
          color: theme('colors.heroblue.DEFAULT'),
          textDecoration: 'underline',

          '&:active, &:hover': {
            outline: 0
          },

          '&:disabled': {
            color: theme('colors.text.muted'),
            textDecoration: 'none',
            cursor: 'text'
          }
        },

        'address': {
          fontStyle: 'normal'
        },
        'abbr[title]': {
          cursor: 'help'
        },
        'hr': {
          marginTop: theme('spacing.5'),
          marginBottom: theme('spacing.5'),
          borderTopWidth: '1px',
          borderTopColor: theme('colors.gray.300')
        },
        'ol, ul': {
          listStyle: 'none'
        },
        'sup': {
          fontSize: '60%',
          letterSpacing: 'normal'
        },

        'pre': {
          overflow: 'auto'
        },
        'span': {
          fontFamily: 'inherit'
        },
        'table': {
          borderSpacing: 0
        },
        'tr, td': {
          padding: 0
        },
        'dt': {
          fontWeight: theme('fontWeight.semibold')
        },
        'dd': {
          marginLeft: 0
        },
        'b, strong': {
          fontWeight: theme('fontWeight.bold')
        },
        '[disabled]': {
          cursor: 'default'
        }
      })
    }),
    plugin(({ addUtilities, theme }) => {
      addUtilities({
        '.ellipsis-overflow': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '.break-long-string': {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          hyphens: 'auto'
        },

        '.fixed-cover': {
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        },
        '.absolute-cover': {
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        },
        '.absolute-center': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        },
        '.flex-center': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        '.hide-scrollbar': {
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        },

        '.scrollbar-gutter-stable': {
          scrollbarGutter: 'stable'
        },

        '.scrollbar-light': {

          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar:horizontal': {
            height: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'rgba(125, 125, 125, 0.15)',
            minHeight: '30px',
            '&:hover': {
              background: 'rgba(125, 125, 125, 0.3)'
            }
          },
        },
        '.scrollbar-dark': {

          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar:horizontal': {
            height: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'rgba(255, 255, 255, 0.3)',
            minHeight: '30px',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.5)'
            }
          },
        },

        //make sure we only even have weight normal with serif font (this overrides default heading for now)
        '.font-serif': {
          fontWeight: 'normal',
          letterSpacing: '0.035rem' //custom per design
        },

        //classess that are not deprecated/are useful but are opinionated.
        '.list-unstyled': {
          paddingLeft: 0,
          listStyle: 'none'
        },

        '.list-inline': {
          paddingLeft: 0,
          listStyle: 'none',
          marginLeft: '-5px',
          marginRight: '-5px',

          '> li': {
            display: 'inline-block',
            paddingRight: '5px',
            paddingLeft: '5px'
          }
        },

        '.underline-on-hover': {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }

      });
    }),



  ]
};
