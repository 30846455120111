export function useExternalRedirect() {
  return {
    setSignupCookie({type, value, context}) {
      //todo: rework and test when the use case is available
      /*setCookie(
        'sign_up',
        escape(JSON.stringify({
          type,
          value,
          context
        })),
        getDomain(),
        fDateTime().add(1, 'day')
      );*/
    },

    setRedirectCookie(redirectTo) {
      if (redirectTo) {
        const redirectValue = typeof redirectTo === 'string'
          ? `${window.location.protocol}//${window.location.host}${redirectTo}`
          : window.location.href;

        //set session cookie
        useCookie('redirect_to').value = redirectValue;
      }
    },

    signUp(redirectTo) {
      if (redirectTo) {
        this.setRedirectCookie(redirectTo);
      }

      window.location = EXTERNAL_URLS.signUp;
    },

    signIn(redirectTo) {
      if (redirectTo) {
        this.setRedirectCookie(redirectTo);
      }
      window.location = EXTERNAL_URLS.signIn;
    },

    /**
     * @param userId
     * @param id - the product to add to cart
     * @param category - can be projects or jupiter
     * @param isUserAuthenticated
     * @param promotionCode
     */
    addToCart({userId, id, category = 'projects', isUserAuthenticated, promotionCode}) {
      const url = buildRouteParams(EXTERNAL_URLS.addToCart, {userId, category, id});
      const queryString = buildQueryString({code: promotionCode});
      const destUrl = `${url}${queryString}`;

      if (isUserAuthenticated) {
        window.location = destUrl;
      } else {
        this.signUp(destUrl);
      }
    }
  };
}
