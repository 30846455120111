<template>
  <file-picker :context="context" :context-type="contextType" :max-selection-count="1" @close="modal.close()" @complete="complete">
    <template #title>
      Select a thumbnail for <em>"{{name || context.name || 'Unnamed'}}"</em>
    </template>
  </file-picker>
</template>

<script setup>
  const emit = defineEmits(['complete']);
  const props = defineProps({
    modal: Object,
    context: Object,
    contextType: String,

    //for when I don't have a context (Group, Profile)
    name: String
  });

  function complete({items}) {
    emit('complete', items[0]);
  }

</script>
