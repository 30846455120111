import {ProjectAddModal, ProjectCopyModal, ProjectPrintModal} from '#components';

export function useProjectActions() {
  return {
    openNewPrintProjectModal() {
      return new Promise(async () => {
        const modal = useFModal({
          id: 'new-print-project-modal',
          size: 'xl',
          component: ProjectAddModal
        });

        modal.open();
      })
    },

    openContainerPrintModal({context, contextType}) {
      useFModal({
        id: 'container-print-modal',
        size: 'lg',
        component: ProjectPrintModal,
        props: {
          context,
          contextType
        }
      }).open();
    },

    async openFilePrintModal({file}) {
      await (new Promise(resolve => {
        const modal = useFModal({
          id: 'file-print-modal',
          size: 'lg',
          component: ProjectPrintModal,
          props: {
            file
          }/*,
          eventHandlers: {
            async singlePhotoPrint() {
              modal.close();
              resolve(true);
            }
          }*/
        });

        modal.open();
      }));

      /*if (shouldOpenPhotoPrintsModal) {
        await this.openPhotoPrintsBulkEditModal();
      }*/
    },

    copyProject({project, isEditAsCopy}) {
      return new Promise(resolve => {
        const modal = useFModal({
          id: 'project-copy',
          component: ProjectCopyModal,
          props: {
            project,
            isEditAsCopy
          },
          eventHandlers: {
            complete({projectCopy}) {
              resolve({projectCopy});
            }
          }
        });

        modal.open();
      })
    },

    async addProjectToCart({project, ownerId, isJupiterContext}) {
      const sessionStore = useSessionStore();
      const pricingStore = usePricingStore();

      const isUserAuthenticated = sessionStore.isAuthenticated;
      const userId = ownerId || sessionStore.currentUserId;
      const promotionCode = pricingStore.promotions?.find(promotion => promotion.id === project.product.id)?.primary_promotion?.code;

      //artisan project or already printable (meaning Jupiter project with a PDF), go direct to cart
      if (!project.is.jupiterProject || project.is.jupiterProjectPrintable) {
        useExternalRedirect().addToCart({userId, id: project.id, isUserAuthenticated, promotionCode});
        return;
      }

      //file is not purchasable, display a toast and return
      if (!project.is.purchasable) {
        useToast().add({
          title: `Can't purchase`,
          description: 'This project is still processing. Please try again shortly.'
        });
        return;
      } else if (project.is.jupiterProject && !project.is.ownedByOriginalOwner) {
        useToast().add({
          title: `Can't purchase`,
          description: 'You cannot purchase this project.'
        });
        return;
      }

      navigateTo(`/app/print-designer/${project.provider_uid}${buildQueryString({addToCart: true})}`, {external: true});
    }
  }
}
