<template>
  <section>
    <u-form :state="fileInfo" :schema="schema" @submit="save" ref="form" :validate-on="['submit']">
      <core-modal-header>
        Bulk Organize: {{files.length}} item(s)
      </core-modal-header>

      <core-modal-body>
        <u-alert
          v-if="!isSingleFileMode"
          color="heroblue"
          class="mb-4"
          :icon="COMMON_ICONS.information"
          title="Editing Multiple Files"
          description="Updating file info will overwrite existing details. Blank fields won't be altered."
        />

        <u-form-group label="Edit Name" name="name" :required="isSingleFileMode" class="mb-4">
          <u-input v-model="fileInfo.name" placeholder="Enter Name" size="lg" />
        </u-form-group>

        <u-form-group label="Edit Description" class="mb-4">
          <u-textarea placeholder="Enter Description" size="lg" v-model="fileInfo.description" />
        </u-form-group>

        <u-form-group v-if="!isProjectFile" label="Edit Date Taken" name="date" :required="isSingleFileMode" class="mb-4" help="Note: Timestamp can only be edited in the lightbox." :ui="{help: 'text-xs'}">
          <core-date-picker
            size="lg"
            v-model="fileInfo.date"
            timezone="UTC"
          />
        </u-form-group>

        <u-form-group label="Add to Albums" class="mb-4">
          <album-select-menu size="lg" v-model="fileInfo.albums" />
        </u-form-group>

        <u-form-group label="Add Tags" class="mb-4">
          <tag-select-menu size="lg" v-model="fileInfo.tags" />
        </u-form-group>

        <u-form-group label="Add People" class="mb-4">
          <people-select-menu size="lg" v-model="fileInfo.people" />
        </u-form-group>

      </core-modal-body>

      <core-modal-footer>
        <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
        <u-button type="submit" :loading="isSaving">Save Changes</u-button>
      </core-modal-footer>
    </u-form>
  </section>
</template>

<script setup>
  import {object, string, date} from 'yup';

  const emit = defineEmits(['success']);

  const props = defineProps({
    files: Array,
    modal: Object
  });

  const filesStore = useFilesStore();
  const fileIds = props.files.map(f => f.forever_file_id || f.id);
  const isSingleFileMode = props.files.length === 1;
  const fileInfo = reactive({
    name: null,
    description: null,
    date: null,
    tags: [],
    albums: [],
    people: []
  });

  const isSaving = ref(false);
  const isProjectFile = computed(() => isSingleFileMode && props.files[0].is.project);

  const schema = object({
    name: isSingleFileMode ? string().required('This field is required.').max(FILE_NAME_MAXLENGTH, `Must be at most ${FILE_NAME_MAXLENGTH} characters`) : null,
    date: isProjectFile.value || !isSingleFileMode ? null : date().required('This field is required.')
  });

  async function save() {
    isSaving.value = true;

    try {
      const payload = Object.assign({}, fileInfo);

      payload.tagNames = fileInfo.tags?.map(t => t.name);
      delete payload.tags;

      payload.albumIds = fileInfo.albums?.map(a => a.id);
      delete payload.albums;

      payload.personIds = fileInfo.people?.map(p => p.id);
      delete payload.people;

      await useFileApi().updateFiles({ids: fileIds, updates: payload});
      filesStore.getFiles({reload: true});

      useSuccessToast().add({
        id: 'files-update-success',
        description: `Success! The ${fileIds.length} selected files were updated.`
      });

      if (filesStore.collectionDescriptor.contextType === FILE_CONTEXTS.tag) {
        fileInfo.tags.forEach(tag => filesStore.updateContext({contextId: tag.id, contextType: FILE_CONTEXTS.tag}));
      }
      if (filesStore.collectionDescriptor.contextType === FILE_CONTEXTS.album) {
        fileInfo.albums.forEach(album => filesStore.updateContext({contextId: album.id, contextType: FILE_CONTEXTS.album}));
      }
      if (filesStore.collectionDescriptor.contextType === FILE_CONTEXTS.person) {
        fileInfo.people.forEach(person => filesStore.updateContext({contextId: person.id, contextType: FILE_CONTEXTS.person}));
      }

      emit('success');

    } catch (e) {
      useErrorToast().add();
    }
  }

  function close() {
    props.modal.close();
  }

  onMounted(async () => {
    if (isSingleFileMode) {
      const file = props.files[0];

      fileInfo.name = file.name;

      const fileDetail = file.is.detail ? file : await useFileApi().getFileDetails([file.id]).then(r => r[0]);
      fileInfo.description = fileDetail.description;
      fileInfo.date = fileDetail.taken_at;
    }
  });

</script>
