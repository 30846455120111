<template>
  <div class="relative">

    <div :class="hasBackgroundImage ? 'h-110' : null" class="relative">

      <div class="absolute-cover bg-center bg-contain" :style="{backgroundImage}" v-if="hasBackgroundImage">
        <div class="absolute-cover bg-center bg-contain bg-no-repeat backdrop-blur-2xl" :style="{backgroundImage}" />
      </div>

      <div class="p-4 sm:p-6 sm:pb-4 bottom-0 rounded-tl rounded-tr text-center" :class="hasBackgroundImage ? 'absolute left-5 right-5 sm:left-10 sm:right-10  bg-white/65 backdrop-blur-md' : 'bg-shell'">
        <p class="text-xs text-gray-500 uppercase">Album by <nuxt-link :to="useBuildRoute().toUserProfile({user: album.user})">{{album.user.name}}</nuxt-link></p>
        <h3 class="font-serif text-4xl sm:text-5xl py-1">{{album.name}}</h3>
        <p v-if="album.displayDate" class="text-gray-500 text-lg sm:text-xl">{{album.displayDate}}</p>
      </div>
    </div>

    <div class="bg-white/75 mx-4 sm:mx-8 p-5 sm:p-10">
      <core-fancy-text-display :text="album.description" />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    album: Object,
    modal: Object
  });

  const hasBackgroundImage = !!props.album.images?.large.url;

  const backgroundImage = computed(() => {
    if (hasBackgroundImage) {
      return `url(${props.album.images?.large.url})`;
    }
  });
</script>
