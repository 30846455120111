<template>
  <section>

    <template v-if="canSharingBeDisabled">
      <u-form-group label="Share Link" size="xl" :ui="{wrapper: 'flex justify-between'}">
        <core-confirm-popover :confirm-method="updateSharing" confirm-text="Confirming will permanently delete the current share link. Continue?" :confirm-when="isSharingEnabled">
          <u-toggle v-model="isSharingEnabled" class="pointer-events-none" />
        </core-confirm-popover>
      </u-form-group>
      <p class="text-sm text-charcoal-400">Anyone on the internet with the link can view and download this content.</p>
    </template>

    <p v-else-if="shareType === 'profile'">This link enables you to share your FOREVER profile with anyone.</p>
    <p v-else>Anyone on the internet with the link can view and download this content.</p>


    <transition name="f-fade">
      <div v-if="isSharingEnabled" class="mt-4">

        <core-contextual-loading-box class="inline-block" v-if="isGettingUrl" loading-text="" size="sm" />

        <div v-else class="flex gap-3">
          <div>
            <use-clipboard v-slot="{ copy, copied }" :source="shareLink">
              <u-tooltip :prevent="!copied" text="Copied!" :popper="{placement: 'top'}">
                <u-button variant="outline" icon="i-ri-link" @click="copy()">Copy Link</u-button>
              </u-tooltip>
            </use-clipboard>
          </div>

          <div>
            <u-popover mode="hover" v-model:open="qrCodeOpen" :open-delay="150">
              <u-button variant="outline" icon="i-ri-qr-code-line" @click="downloadQrCode">QR Code</u-button>

              <template #panel>
                <div class="p-2 w-40 flex flex-col items-center cursor-pointer gap-1" @click="downloadQrCode">
                  <div ref="qrCodeCanvas" />
                  <p class="text-2xs text-gray uppercase">Click to Download</p>
                </div>
              </template>
            </u-popover>
          </div>
        </div>

        <div class="hidden" ref="qrCodeDownloadCanvas" />


        <div v-if="isAlbumShare">
          <hr class="border-gray-100 my-4">
          <u-form-group label="Allow anyone with the link to upload" :ui="{wrapper: 'flex justify-between items-center'}">
            <u-checkbox :model-value="isCollaborative" @update:modelValue="updateCollaboration" />
          </u-form-group>

          <p class="text-sm text-charcoal-400 leading-tight mt-1">
            Enables anyone with the link who has an account to upload files to this album. Files uploaded will belong to
            you, appear in your Gallery, and count against your storage.
          </p>
        </div>


        <div v-if="canEmbed">
          <hr class="border-gray-100 my-4">
          <use-clipboard v-slot="{ copy, copied }" :source="embedMarkup">
            <u-tooltip :prevent="!copied" :close-delay="3000" text="Embed code copied to your clipboard." :popper="{placement: 'top'}">
              <u-button variant="link" :padded="false" icon="i-ri-code-s-slash-line" @click="toggleEmbed(copy)">Embed</u-button>
            </u-tooltip>
          </use-clipboard>
          <transition name="f-fade">
            <pre v-if="showEmbedMarkup" class="whitespace-normal text-2xs mt-2 p-3 bg-shell-200 border border-shell">{{embedMarkup}}</pre>
          </transition>
        </div>

      </div>

    </transition>
  </section>
</template>

<script setup>
  import {UseClipboard} from '@vueuse/components';
  import {generateQRCode} from '~/services/core/qr-code';

  const props = defineProps({
    shareType: {
      type: String,
      required: true
    },
    shareObject: {
      type: Object,
      required: true
    }
  });

  const urlShortener = useUrlShortener();
  const isCollaborative = ref(false);
  const isSharingEnabled = ref(true);
  const qrCode = ref(null);
  const qrCodeDownload = ref(null);
  const qrCodeOpen = ref(false);
  const isUpdatingSharing = ref(false);
  const isUpdateSharingPopoverOpen = ref(false);
  const currentUser = useSessionStore().currentUser;
  const userId = currentUser.slug;
  const isAlbumShare = props.shareType === 'album';
  const showEmbedMarkup = ref(false);
  const canEmbed = props.shareType === 'file' && (props.shareObject.streamable || props.shareObject.is.audio);
  const canSharingBeDisabled = ['album', 'tag', 'file'].includes(props.shareType);
  const shareObjectId = props.shareObject.id;
  const qrCodeCanvas = ref();
  const qrCodeDownloadCanvas = ref();
  const embedMarkup = computed(() => {
    if (canEmbed) {
      const route = buildRouteParams(SHARE_ROUTES.embed, {
        userId,
        fileId: props.shareObject.id,
        shareToken: props.shareObject.share_token
      });
      const src = `${window.location.origin}${route}`;
      const {width, height} = props.shareObject.is?.audio
          ? {
            width: SHARE_EMBED_FRAME_MAX_VIDEO_DIMENSIONS,
            height: Math.round(SHARE_EMBED_FRAME_MAX_VIDEO_DIMENSIONS * 0.5)
          }
          : (() => {
            const scaleRatio = SHARE_EMBED_FRAME_MAX_VIDEO_DIMENSIONS / Math.max(props.shareObject.width, props.shareObject.height);

            return {
              width: Math.floor(props.shareObject.width * scaleRatio),
              height: Math.floor(props.shareObject.height * scaleRatio)
            };
          })();
      return `<iframe width="${width}" height="${height}" src="${src}" style="border:none;overflow:hidden" allowfullscreen />`;
    }
  });

  const {data: shareLink, pending: isGettingUrl} = await useAsyncData(
    'shortened-url',
    async () => {
      const base = document.location.origin;
      let route;

      switch (props.shareType) {
        case 'album':
        case 'tag':
        case 'container':
          route = buildRouteParams(SHARE_ROUTES[props.shareType], {
            userId,
            id: props.shareObject.slug || props.shareObject.id, //always fall back on ID since containers do not have slugs
            shareToken: props.shareObject.share_token
          });
          break;
        case 'file':
          route = buildRouteParams(SHARE_ROUTES.file, {
            userId,
            fileId: props.shareObject.id,
            shareToken: props.shareObject.share_token
          });
          break;
        case 'profile':
          route = buildRouteParams(SHARE_ROUTES[props.shareType], {
            userId,
            id: props.shareObject.slug
          });
          break;
      }


      try {
        return await urlShortener.getShortenedUrl(`${base}${route}`);
      } catch (e) {
        return `${base}${route}`;
      }
    }
  );

  watch(() => props.shareObject.collaborative, newVal => (isCollaborative.value = newVal), {immediate: true});
  watch(() => props.shareObject.share_token, newVal => {
    if (canSharingBeDisabled) {
      isSharingEnabled.value = Boolean(newVal);
    }
  }, {immediate: true});

  async function updateCollaboration(value) {
    try {
      isCollaborative.value = value;
      await useAlbumsStore().updateAlbum({id: shareObjectId, updates: {collaborative: value}});
    } catch (e) {
      useErrorToast().add();
      isCollaborative.value = props.shareObject.collaborative;
    }
  }

  async function updateSharing() {
    isUpdateSharingPopoverOpen.value = false;

    const isEnabling = !isSharingEnabled.value;
    const store = (() => {
      switch (props.shareType) {
        case 'file':
          return useFilesStore();
        case 'album':
          return useAlbumsStore();
        case 'tag':
          return useTagsStore();
      }
    })();

    isUpdatingSharing.value = true;

    try {
      store[isEnabling ? 'resetShareToken' : 'disableShareToken']({id: shareObjectId});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isUpdatingSharing.value = false;
    }
  }

  async function toggleEmbed(copyFn) {
    showEmbedMarkup.value = !showEmbedMarkup.value;

    if (showEmbedMarkup.value) {
      copyFn();
    }
  }

  function downloadQrCode() {
    const link = document.createElement('a');
    link.download = `qr_${props.shareObject.name}.png`;
    link.href = qrCodeDownload.value?._oDrawing.dataURL;
    link.click();

    useSuccessToast().add({
      id: 'qr-dl-sucvess',
      description: 'QR Code downloaded to your Downloads folder.'
    })

  }

  function clearQrCode() {
    if (qrCode.value) {
      qrCode.value.clear();
      qrCode.value = null;
    }
  }

  watch(qrCodeOpen, async (newVal) => {
      if (newVal && isSharingEnabled) {
        await nextTick();

        qrCode.value = generateQRCode(qrCodeCanvas.value, {
          text: shareLink.value,
          size: 131
        });
      }
  });

  onMounted(() => {
    watch(isSharingEnabled, async (newVal) => {
      if (newVal) {
        await nextTick();

        qrCodeDownload.value = generateQRCode(qrCodeDownloadCanvas.value, {
          text: shareLink.value,
          size: 600
        });
      } else {
        clearQrCode();
      }
    }, {immediate: true});
  });

  onUnmounted(() => {
    clearQrCode();
  });

</script>
