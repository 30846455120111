<template>
  <div>
    <u-form :state="state" :schema="schema" @submit="saveAlbum" :validate-on="['submit']">
      <core-modal-header v-if="modal">{{headingText}}</core-modal-header>

      <component :is="modal ? CoreModalBody : 'div'">
        <div class="grid sm:grid-cols-2 divide-y sm:divide-x sm:divide-y-0 divide-gray-200">
          <div class="sm:pr-6 pb-4 sm:pb-0">
            <u-form-group label="Album Name" name="name" required class="mb-4" ref="albumEditName">
              <u-input placeholder="Enter album name" autofocus v-model="state.name" />
            </u-form-group>

            <u-form-group label="Album Description" class="mb-4">
              <u-textarea
                placeholder="Enter a description"
                v-model="state.description"
                :rows="5"
                :cols="30"
                :maxlength="descriptionMaxLength" />

              <template #hint>
                <core-input-length-counter
                  v-model="state.description"
                  :limit="descriptionMaxLength"
                  :display-at="descriptionCharCountWarnAt" />
              </template>
            </u-form-group>

            <u-form-group label="Album Date">
              <core-date-picker
                timezone="UTC"
                v-model="state.start_date"
              />
            </u-form-group>

            <u-form-group v-if="isEndDateDisplayed" name="end_date">
              <span class="text-sm my-1">to</span>

              <core-date-picker
                timezone="UTC"
                :disabled="!state.start_date"
                v-model="state.end_date"
              />
            </u-form-group>

            <!-- toggle end date -->
            <div class="mt-3">
              <u-button
                variant="link"
                :icon="isEndDateDisplayed ? `i-ri-indeterminate-circle-line` : 'i-ri-add-circle-line'"
                :padded="false"
                color="charcoal"
                size="sm"
                @click.prevent="toggleEndDate">

                {{isEndDateDisplayed ? `Don't Use Date Range` : 'Use Date Range'}}
              </u-button>
            </div>
          </div>

          <div class="sm:pl-6 pt-4 sm:pt-0">
            <album-permission-editor :parent="parent" v-model:album="state" />
          </div>
        </div>
      </component>

      <component :is="modal ? CoreModalFooter : 'div'" :class="modal ? '' : 'pt-6 text-right'">
        <u-button color="charcoal" variant="ghost" v-if="modal" @click="modal.close()">Cancel</u-button>

        <u-button type="submit" :loading="isSaving">
          <span v-if="buttonSaveText">{{buttonSaveText}}</span>
          <span v-else>{{isEditing ? 'Update Album' : 'Create New Album'}}</span>
        </u-button>
      </component>
    </u-form>
  </div>
</template>

<script setup>
  import {object, string, date} from 'yup';
  import {CoreModalBody, CoreModalFooter} from '#components';
  import {ALBUM_MAX_NAME_LENGTH} from '~/constants/albums.js';

  const emit = defineEmits(['success']);
  const props = defineProps({
    parent: Object,
    buttonSaveText: String,
    album: Object,
    modal: Object,
    showSuccessToast: {
      type: Boolean,
      default: true
    }
  });

  const parentAlbumOverrides = props.parent ? { parent_id: props.parent.id, privacy: props.parent.privacy } : null;

  const state = ref(Object.assign(
    {
      name: null,
      privacy: ALBUM_PERMISSION_LAYERS.private.key,
      description: null,
      start_date: null,
      end_date: null
    },
    props.album,
    parentAlbumOverrides
  ));

  const albumEditName = ref();
  const isSaving = ref(false);
  const isEditing = Boolean(props.album);

  const descriptionMaxLength = 15000;
  const descriptionCharCountWarnAt = 12000;

  const isEndDateDisplayed = ref(false);

  const headingText = (() => {
    if (isEditing) {
      return 'Edit Album';
    } else if (state.value?.parent_id) {
      return 'Create Nested Album';
    } else {
      return 'Create Album';
    }
  })();

  const schema = object({
    name: string().required('This field is required.').max(ALBUM_MAX_NAME_LENGTH, `Max length cannot exceed ${ALBUM_MAX_NAME_LENGTH} characters.`),
    end_date: date().when('start_date', (start_date, schema) => {
      if (isEndDateDisplayed.value && start_date) {
        const dayAfter = new Date(start_date); // + 1
        return schema.min(dayAfter, 'The end date must be after the start date.');
      }

      return schema;
    }).nullable()
  });

  watch(() => state.value.start_date, newVal => {
    if (newVal) {
      // startDateYear = fDateTime(newVal).utc().year();
    } else {
      toggleEndDate();
    }
  });

  onMounted(() => {
    if (state.value.end_date) {
      isEndDateDisplayed.value = true;
    }
  });

  function toggleEndDate() {
    isEndDateDisplayed.value = !isEndDateDisplayed.value;

    if (!isEndDateDisplayed.value) {
      state.value.end_date = null;
    }

    // this.$emit('input', Object.assign({}, this.value, {end_date: null})); //todo: fix when we have a date picker
  }

  async function saveAlbum() {
    isSaving.value = true;

    try {
      const newAlbum = isEditing
        ? await useAlbumsStore().updateAlbum({id: state.value.id, updates: state.value})
        : await useAlbumsStore().createAlbum({payload: state.value});

      if (props.showSuccessToast) {
        useSuccessToast().add({
          id: 'album-edit-success',
          description: `Your album ${html2text(state.value.name)} was saved.`,
          actions: [
            !isEditing ? {
              label: 'View Album',
              click: () => navigateTo(useBuildRoute().toAlbum({album: newAlbum}))
            } : null
          ].filter(Boolean)
        });
      }

      emit('success', {newAlbum});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
